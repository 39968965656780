/** @format */

const Web3 = require("web3");
const { NotificationManager } = require("react-notifications");

const infuraURL = process.env.REACT_APP_NODE;
const provider = new Web3.providers.WebsocketProvider(infuraURL);
// const provider = new Web3.providers.HttpProvider("your_ethereum_node_url");

const web3 = new Web3(provider);

const tokenAbi = require(`../assets/abi-erc20.json`);

const PRESALE_ADDRESS = "0x3b4c831c4006bf4D441b1B5e19A5170884CEc675";

const contract = new web3.eth.Contract(tokenAbi, PRESALE_ADDRESS);

const eventOptions = {
  fromBlock: "pending",
  filter: {},
};

const displayTransactionInfo = tx => {
  web3.eth.getTransaction(tx, function (err, txInfo) {
    try {
      NotificationManager.success(
         `Mine worth  ${web3.utils.fromWei(txInfo.value)} Burgers`,
      );
    } catch (e) {
      displayTransactionInfo(tx);
    }
  });
};

const finalize = () => {
  console.log("monitoring miners");
  contract.events
    .allEvents(eventOptions)
    .on("data", event => {
      displayTransactionInfo(event.transactionHash);
    })
    .on("error", console.error);
};

module.exports = {
  finalize,
};
