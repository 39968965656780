/** @format */

import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className='d-flex align-items-center justify-content-center vh-100'>
      <div className='text-center row'>
        <div className=' col-md-6'>
          <img src='assets/404-error.png' alt='' className='img-fluid' />
        </div>
        <div className=' col-md-6 m-auto'>
          <p className='fs-3 text-light'>
            {" "}
            <span className='text-danger'>Opps!</span> Page not found.
          </p>
          <p className='lead text-light'>
            The page you’re looking for doesn’t exist.
          </p>
          <Link to='/' className='btns orange text-white'>
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
