/** @format */

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Base from "./components/layout/Base";
import PageNotFound from "./components/element/PageNotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import {
  bsc,
  mainnet,
  polygon,
  moonbeam,
  avalanche, 
  fantom,
} from "viem/chains";
import ReactGA from "react-ga";
ReactGA.initialize("G-R15PPCDPVT");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  const chains = [bsc, mainnet, polygon, moonbeam, avalanche, fantom];
  const projectId = "1c200fb2c2e182bbda9170cd52450702";
  const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId }),
  ]);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient,
  });
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <WagmiConfig config={wagmiConfig}>
      <Router>
        <Base>
          <Switch>
            <Route exact path='/'>
              <Home />
            </Route>
            <Route component={PageNotFound} />
          </Switch>
        </Base>
      </Router>
      <NotificationContainer />
      <div className=' z-2  postion-relative'>
        <Web3Modal
          projectId={projectId}
          ethereumClient={ethereumClient}
          themeMode='light'
          themeVariables={{
            "--w3m-background": "#f2b04f",
            "--w3m-background-color": "#e55643",
            "--w3m-text-primary": "#000",
            "--w3m-color-bg-1": "#f2b04f",
            "--w3m-color-bg-2": "#e55643",
            "--w3m-color-bg-3": "#e55643",
            "--w3m-color-fg-2": "#fff",
            "--w3m-color-fg-3": "#fff",
            "--w3m-color-fg-1": "#fff",
            "--w3m-accent-color": "#fff",
          }}
        />
      </div>{" "}
    </WagmiConfig>
  );
}

export default App;
