/** @format */

import React from "react";

const Taste = () => {
  return (
    <>
      <div className='row mt-3'>
        <div className='ref m-auto'>
          <div className='card-body'>
            <h4 className='text-center'>Taste Pizza X</h4>

            {/* Row  */}
            <div className='row mt-4'>
              <div className='col-6'>
                <p>Daily Return</p>
              </div>
              <div className='col-6 text-end'>
                <p>15%</p>
              </div>
            </div>
            {/* Row  */}
            <div className='row mt-3'>
              <div className='col-6'>
                <p>APR</p>
              </div>
              <div className='col-6 text-end'>
                <p>4000%</p>
              </div>
            </div>
            {/* Row  */}
            <div className='row mt-3'>
              <div className='col-6'>
                <p>Dev Fee</p>
              </div>
              <div className='col-6 text-end'>
                <p>5%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Taste;
