/** @format */

import { AiFillTwitterCircle } from "react-icons/ai";
import {
  FaDiscord,
  // FaYoutube,
  FaInstagram,
  FaGithub,
  FaFacebook,
  FaTelegram,
  FaMediumM,
  FaReddit,
  FaBook,
} from "react-icons/fa";
const Footer = () => {
  return (
    <footer className='pt-3 footer' id='footer'>
      <div className='container pt-5 footer'>
        <div className='row text-center'>
          <div className='col-lg-12'>
            <div className='font-weight-light text-center'>
              <ul className='list-unstyled '>
                {/* <li>
             
                  <a
                    rel='noreferrer'
                    href=''
                    target='_blank'
                    id='coming-soon'>
                    <img
                      src='assets/bsc.png'
                      width={24}
                      alt=''
                      rel='noreferrer'
                      />
                  </a>
                </li> */}

        
                <li> 
                  <a
                    rel='noreferrer'
                    href='https://pizzax.gitbook.io/pizzax.club/'
                    target='_blank'
                    id='coming-soon'>
                    <FaBook style={{ fontSize: 24 }} className='text-light' />
                  </a>
                </li>
                {/* <li>
                  <a
                    target='_BLANK'
                    href='https://pizzax.gitbook.io/pizzax.club/'
                    rel='noreferrer'>
                    <FaMediumM
                      style={{ fontSize: 24 }}
                      className='text-light'
                    />
                  </a>
                </li> */}
                <li>
                  <a
                    target='_BLANK'
                    href='https://twitter.com/PizzaXXX'
                    rel='noreferrer'>
                    <AiFillTwitterCircle
                      style={{ fontSize: 24 }}
                      className='text-light'
                    />
                  </a>
                </li>
                <li>
                  <a
                    target='_BLANK'
                    href='https://t.me/PizzaXXX_Official'
                    rel='noreferrer'>
                    <FaTelegram
                      style={{ fontSize: 24 }}
                      className='text-light'
                    />
                  </a>
                </li>
                {/* <li>
                  <a
                    target='_BLANK'
                    href='#!'
                    rel='noreferrer'>
                    <FaDiscord
                      style={{ fontSize: 24 }}
                      className='text-light'
                    />
                  </a>
                </li>
                <li>
                  <a
                    target='_BLANK'
                    href='#!'
                    rel='noreferrer'>
                    <FaInstagram
                      style={{ fontSize: 24 }}
                      className='text-light'
                    />
                  </a>
                </li> */}
                <li>
                  <a
                    target='_BLANK'
                    href='https://github.com/Pizzaxxx'
                    rel='noreferrer'>
                    <FaGithub style={{ fontSize: 24 }} className='text-light' />
                  </a>
                </li>
                {/* <li>
                  <a target="_BLANK" href="">
                    <FaYoutube />
                  </a>
                </li> */}
                {/* <li>
                  <a
                    target='_BLANK'
                    href='#!'
                    rel='noreferrer'>
                    <FaReddit style={{ fontSize: 24 }} className='text-light' />
                  </a>
                </li>
                <li>
                  <a
                    target='_BLANK'
                    href='#!'
                    rel='noreferrer'>
                    <FaFacebook
                      style={{ fontSize: 24 }}
                      className='text-light'
                    />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
