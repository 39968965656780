/** @format */

import React, { useEffect } from "react";
import {
  CardBox,
  Hero,
  List,
  Referance,
  Taste,
} from "../../components/element";

import { finalize } from "../../components/tokenData/actions/quick";
const Home = () => {
  useEffect(() => {
    // finalize();
  }, []);
  return (
    <React.Fragment>
      <div className='container m-auto'>
        <Hero />
        <CardBox />
        <div className='row m-auto'>
          <div className='col-md-6 m-auto'>
            <Referance />
          </div>
          <div className='col-md-6 m-auto'>
            <Taste />
          </div>

          {/* <div className='col-md-4'>
            <List />
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Home;
