/** @format */

import React, { useState, useEffect } from "react";
import {
  getBakeContract,
  getERC20Contract,
} from "../../../tokenData/store/contractStore";
import config from "../../../tokenData/assets/config.json";
import Web3 from "web3";
import { useAccount, useSwitchNetwork, useNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";
import { toast } from "react-toastify";
import useRequest from "@ahooksjs/use-request";
import CountUp from "react-countup";
import { useLocation } from "react-router-dom";
import numbro from "numbro";
import { utils } from "ethers";
import useAllowance from "../../../tokenData/actions/useAllowance";
import useBalance from "../../../tokenData/actions/useBalance";
import TokenListRinkeby from "../../../tokenData/assets/token-list-mainnet.json";
const MAX_UINT =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

const PizzaXPool = () => {
  const [selectedToken, setSelectedToken] = useState(TokenListRinkeby[5]);
  useEffect(() => {
    setSelectedToken(TokenListRinkeby[5]);
  }, []);

  const [balance] = useBalance(selectedToken.address, selectedToken.decimals);
  // stats

  const busdBeans = config[0].pieBeans;
  const busd = config[0].pie;
  let allowanceWeb3 = useAllowance(busd, busdBeans);
  const [allowance, setAllowance] = useState("0");
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { open } = useWeb3Modal();

  const { switchNetwork } = useSwitchNetwork();

  const [tokenValue, setTokenValue] = useState("");
  const [contractValue, setContractvalue] = useState(0);
  const [myRewardval, setmyRewardval] = useState(0);

  const [myPizzaXval, setmyPizzaXval] = useState(0);
  var web3 = new Web3(Web3.givenProvider || "http://localhost:3002/");

  // contract integration
  const toWei = (value, unit = "ether") => {
    return web3.utils.toWei(value, unit);
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const getRef = () => {
    const ref = web3.utils.isAddress(query.get("ref"))
      ? query.get("ref")
      : "0x22D571D94f674Fdb19fEFA49Cf501f6f8b54c28D";
    return ref;
  };
  const ref = getRef();

  useEffect(() => {
    setAllowance(allowanceWeb3);
  }, [allowanceWeb3]);
  async function approve() {
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = await getERC20Contract(busd, web3);
    contract?.methods
      .approve(busdBeans, MAX_UINT)
      .send({ from: address, gasPrice: rpcGasPrice })
      .then(value => {
        setAllowance(MAX_UINT);
        toast.success("Transaction completed!");
      })
      .catch(error => {
        console.log(error);
        toast.error("User denied transaction signature");
      });
  }

  const getFullDisplayBalance = (balance, format = {}) => {
    return numbro(utils.formatEther(balance)).format(Object.assign({}, format));
  };
  async function getbal() {
    try {
      const contract = getBakeContract(busdBeans, web3);
      contract?.methods
        .getBalance()
        .call()
        .then(value => {
          setContractvalue(getFullDisplayBalance(value));
          // console.log(getFullDisplayBalance(value));
        })
        .catch(error => {
          // console.log(error);
        });
    } catch {
      console.log("failed");
    }
  }

  async function getMyPizzaX() {
    if (isConnected && chain?.id === 56) {
      const contract = getBakeContract(busdBeans, web3);
      contract?.methods
        .getMyMiners(address)
        .call()
        .then(value => {
          setmyPizzaXval(getFullDisplayBalance(value));
          // console.log(value);
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }

  async function getMyReward() {
    if (isConnected && chain?.id === 56) {
      const contract = getBakeContract(busdBeans, web3);
      contract?.methods
        .beanRewards(address)
        .call()
        .then(value => {
          setmyRewardval(getFullDisplayBalance(value));
          // console.log(value);
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }

  const fetchData = async () => {
    await getbal();
  };
  useRequest(fetchData, {
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });

  const fetchData1 = async () => {
    await getMyReward();
    await getMyPizzaX();
  };

  const { run, cancel } = useRequest(fetchData1, {
    manual: true,
    loadingDelay: 500,
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
    throttleInterval: 1000,
    throwOnError: true,
  });
  useEffect(() => {
    if (isConnected) {
      run();
    }
    return () => {
      cancel();
    };
  }, [run, cancel]);
  ////////////////////   busd /////////////////////////////
  // buy
  async function buyEgg() {
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = getBakeContract(busdBeans, web3);
    contract?.methods
      .ovenPie(ref, toWei(tokenValue))
      .send({
        from: address,
        // value: toWei(tokenValue),
        gasPrice: rpcGasPrice,
      })
      .then(value => {
        console.log(value);
        toast.success("Transaction completed!");
        getMyReward();
        getMyPizzaX();
      })
      .catch(error => {
        console.log(error);
        toast.error("User denied transaction signature");
      });
  }

  async function buy() {
    buyEgg();
  }
  //hatch re

  async function hatchEggs() {
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = getBakeContract(busdBeans, web3);
    contract?.methods
      .heatPie(ref)
      .send({
        from: address,
        gasPrice: rpcGasPrice,
      })
      .then(value => {
        console.log(value);
        toast.success("Transaction completed!");
        getMyReward();
        getMyPizzaX();
        // console.log(value);
      })
      .catch(error => {
        console.log(error);
        toast.error("User denied transaction signature");
      });
  }

  async function hatch() {
    hatchEggs();
  }

  //Eat

  async function eatEggs() {
    const rpcGasPrice = await web3.eth.getGasPrice();

    const contract = getBakeContract(busdBeans, web3);
    contract?.methods
      .sellPies()
      .send({
        from: address,
        gasPrice: rpcGasPrice,
      })
      .then(value => {
        console.log(value);
        toast.success("Transaction completed!");
        getMyReward();
        getMyPizzaX();
      })
      .catch(error => {
        console.log(error);
        toast.error("User denied transaction signature");
      });
  }

  async function sell() {
    eatEggs();
  }
  const isAvailable = false;
  return (
    <>
      <div className='cardBox m-auto   '>
        <div className='card-body'>
          <h4 className='text-center heading-1'>
            PizzaX Miner <small>(15% reward/d)</small>
          </h4>

          <div className='row mt-4'>
            <div className='col-6'>
              <p>Contract</p>
            </div>
            <div className='col-6 text-end'>
              <p>
                <strong>
                  {" "}
                  <CountUp
                    delay={2}
                    start={0}
                    decimals={3}
                    end={Math.round(contractValue * 1000) / 1000}
                  />
                  &nbsp; PizzaX
                </strong>
              </p>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-6'>
              <p>Wallet</p>
            </div>
            <div className='col-6 text-end'>
              <p>
                <strong>
                  {" "}
                  <CountUp
                    delay={2}
                    start={0}
                    decimals={3}
                    end={Math.round(balance * 1000) / 1000}
                  />{" "}
                  PizzaX
                </strong>
              </p>
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col-6'>
              <p>Your PizzaX</p>
            </div>
            <div className='col-6 text-end'>
              <p>
                <strong>{myPizzaXval} PizzaX</strong>
              </p>
            </div>
          </div>

          {chain?.id === 56 ? (
            allowance === "0" ? (
              <>
                <div className='input-group inp1 mb-3 mt-4'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='0'
                    disabled
                    aria-describedby='basic-addon2'
                  />
                  <span className='input-group-text'>PizzaX</span>
                </div>
                <button
                  className='btns orange w-100'
                  onClick={approve}
                  disabled={isAvailable}>
                  {isAvailable ? "Live soon" : "Approve"}
                </button>
              </>
            ) : (
              <>
                <div className='input-group inp1 mb-3 mt-4'>
                  <input
                    type='text'
                    className='form-control'
                    placeholder='0'
                    onChange={event => setTokenValue(event.target.value)}
                    aria-describedby='basic-addon2'
                  />
                  <span className='input-group-text'>PizzaX</span>
                </div>
                <div className='mt-4'>
                  {tokenValue ? (
                    <button className='btns orange w-100' onClick={buy}>
                      Bake PizzaX
                    </button>
                  ) : (
                    <button className='btns orange w-100' disabled>
                      Bake PizzaX
                    </button>
                  )}
                  {/* <button className='btn btn-bake' >
                  Coming Soon
                </button> */}
                </div>
              </>
            )
          ) : isConnected ? (
            <button
              className='btns orange w-100 mt-4'
              disabled={!switchNetwork}
              onClick={() => switchNetwork?.("56")}>
              Switch Network
            </button>
          ) : (
            <button className='btns orange mt-4 w-100' onClick={open}>
              Connect Wallet
            </button>
          )}
          <hr />

          <div className='row mt-3 mb-3'>
            <div className='col-6'>
              <p>
                <strong>PizzaX Earned</strong>
              </p>
            </div>
            <div className='col-6 text-end'>
              <p>
                <strong>{Number(myRewardval).toFixed(2)}</strong>
              </p>
            </div>
          </div>
          <div className='row mt-4 mb-2'>
            <div className='col-6 text-center'>
              <button
                className='btns red w-100'
                onClick={hatch}
                disabled={isAvailable}>
                Re-Invest
              </button>
            </div>
            <div className='col-6 text-center'>
              <button
                className='btns red w-100'
                onClick={sell}
                disabled={isAvailable}>
                Eat
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PizzaXPool;
