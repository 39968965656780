/** @format */

import React from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useEnsName } from "wagmi";
const Header = () => {
  const { address, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address });
  const { open } = useWeb3Modal();

  function truncate(str, n) {
    return str.length > n ? str.slice(0, 4) + "..." + str.slice(-4) : str;
  }
  return (
    <>
      <header className='Header' data-header=''>
        <div className='Header-inner' data-header-nav=''>
          <div className='logo'>
            <img src='assets/logo-cr.png' width={70} className='rot' alt='' />
            <img src='assets/logo-txt.png' width={120} alt='' />
          </div>
          <nav className='Header-nav' data-nav=''>
            <div className='Header-nav-item mx-2' data-nav-item={5}>
              <a
                href='https://pancakeswap.finance/swap?outputCurrency=0x488739D593DC2BC13Fd738CBaa35498bad5F8556'
                target='_blank'
                rel='noopener noreferrer'
                className='btns red  blink '>
                Trade on PCS
              </a>
            </div>
            <div className='Header-nav-item mx-2' data-nav-item={5}>
              <a
                href='https://www.pinksale.finance/launchpad/0xaD3A94F438c85c4B92C7b4850c39Eeb831463f60?chain=BSC'
                target='_blank'
                rel='noopener noreferrer'
                className='btns red  '>
                Claim
              </a>
            </div>
            <div className='Header-nav-item ' data-nav-item={5}>
              {isConnected ? (
                <button className='btns orange' onClick={open}>
                  {truncate(
                    `${ensName ? `${ensName} (${address})` : address}`,
                    8,
                  )}
                </button>
              ) : (
                <button onClick={open} className='btns orange   '>
                  Connect Wallet
                </button>
              )}
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
