/** @format */

import { useState, useEffect } from "react";
import { getERC20Contract } from "../store/contractStore";
import BN from "bn.js";
import { useAccount } from "wagmi";
import Web3 from "web3";

export default function useAllowance(tokenAddress, spender) {
  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");
  const { address, isConnected } = useAccount();
  const [allowance, setAllowance] = useState("0");
  // console.log(account, library)
  useEffect(() => {
    let isCancelled = false;

    function getAllowance() {
      return new Promise(resolve => {
        if (!web3 || !tokenAddress || !isConnected) {
          resolve(new BN("0"));
          return;
        }

        try {
          const contract = getERC20Contract(tokenAddress, web3);
          contract?.methods
            .allowance(address, spender)
            .call()
            .then(value => {
              resolve(new BN(value));
            })
            .catch(error => {
              // console.log(error)
              resolve(new BN("0"));
            });
        } catch (error) {
          resolve(new BN("0"));
        }
      });
    }

    async function run() {
      const bn = await getAllowance();
      if (!isCancelled) {
        setAllowance(bn.toString());
      }
    }

    run();

    return () => {
      isCancelled = true;
    };
  }, [tokenAddress, web3, spender, address]);

  // console.log("log === ?", balance);

  return allowance;
}
