/** @format */

import React from "react";

const Hero = () => {
  return (
    <>
      <div className='container hero'>
        <div className='row'>
          <div className='col-lg-12 w-75 m-auto'>
            <h6 className='text-center m-auto text-light title'>
              <span>
                First BSC Pool as a Service Miner. Twist to generate
                15%/daily reward.
              </span>
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
