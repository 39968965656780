/** @format */

import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import TopNav from "./Topbar";
import Header from "./Header";

const Base = props => {
  return (
    <React.Fragment>
      {/* <TopNav /> */}
      {/* <Navbar /> */}
      <Header />
      {props.children}
      <Footer />
    </React.Fragment>
  );
};
export default Base;
