/** @format */

import { useState, useEffect } from "react";
import { ZERO_ADDRESS, web3BNToFloatString } from "../utils";
import { getERC20Contract } from "../store/contractStore";
import BigNumber from "bignumber.js";
import BN from "bn.js";
import { useAccount } from "wagmi";
import Web3 from "web3";

export default function useBalance(tokenAddress, decimals) {
  const [balance, setBalance] = useState("0");

  const web3 = new Web3(Web3.givenProvider || "http://localhost:3000/");
  const { address, isConnected } = useAccount();

  useEffect(() => {
    let isCancelled = false;

    function getBalance() {
      return new Promise(resolve => {
        if (!web3 || !tokenAddress || !isConnected) {
          resolve(new BN("0"));
          return;
        }

        try {
          if (tokenAddress === ZERO_ADDRESS) {
            web3.eth
              .getBalance(address)
              .then(value => {
                resolve(new BN(value));
              })
              .catch(error => {
                console.log(error);
                resolve(new BN("0"));
              });
          } else {
            const contract = getERC20Contract(tokenAddress, web3);
            contract?.methods
              .balanceOf(address)
              .call()
              .then(value => {
                resolve(new BN(value));
              })
              .catch(error => {
                // console.log(error)
                resolve(new BN("0"));
              });
          }
        } catch (error) {
          resolve(new BN("0"));
        }
      });
    }

    async function run() {
      const bn = await getBalance();
      if (!isCancelled) {
        const pow = new BigNumber("10").pow(new BigNumber(decimals));
        setBalance(web3BNToFloatString(bn, pow, 2, BigNumber.ROUND_DOWN));
      }
    }

    run();

    return () => {
      isCancelled = true;
    };
  }, [tokenAddress, web3, decimals, address]);

  // console.log("log === ?", balance);

  return [balance];
}
