/** @format */

import React from "react";
import { BNBPool, BUSDPool, Burger, CAKEPool, PizzaXPool } from "./chains/bsc";

import { ToastContainer } from "react-toastify";
import CountDown from "../utils/Countdown";

const CardBox = () => {
  return (
    <>
      <div className='container mt-3'>
        <div className='row '>
          <div className='col-lg-4 m-auto'>
            <a
              href='https://pancakeswap.finance/swap?outputCurrency=0x488739D593DC2BC13Fd738CBaa35498bad5F8556'
              target='_blank'
              className=' pt-4 '
              rel='noopener noreferrer'>
              <img
                src='assets/pcs.svg'
                width={250}
                className='m-auto d-block'
                alt=''
              />
            </a>
            {/* <a
              href='https://www.pinksale.finance/launchpad/0xaD3A94F438c85c4B92C7b4850c39Eeb831463f60?chain=BSC'
              target='_blank'
              className=' pt-4 '
              rel='noopener noreferrer'>
              <img
                src='assets/pinksale.png'
                width={200}
                className='m-auto d-block'
                alt=''
              />
            </a> */}
            <BNBPool />
          </div>
          <div className='col-lg-4 m-auto'>
            {/* <CountDown /> */}
            <PizzaXPool />
          </div>
          {/* <div className='col-lg-4 m-auto'>
            <Burger />
          </div> */}

          <div className='col-lg-4 m-auto'>
            <a
              href='https://www.dextools.io/app/en/bnb/pair-explorer/0x7cb4161ca48617d438af3c8e130e4d0d8ec80823'
              target='_blank'
              className=' pt-4 '
              rel='noopener noreferrer'>
              <img
                src='assets/dex.png'
                width={200}
                className='m-auto d-block'
                alt=''
              />
            </a>
            <BUSDPool />
          </div>
          {/* <div className='col-lg-4 m-auto'>
            <CAKEPool />
          </div> */}
        </div>
        {/* tabs */}
        {/* <Tab.Container
          id='left-tabs-example'
          className='m-auto'
          defaultActiveKey='Binance'>
          <Nav variant='pills' className='flex-row m-auto nav-img'>
            <Nav.Item className='m-auto'>
              <Nav.Link eventKey='Binance'>
                <OverlayTrigger
                  overlay={<Tooltip>Binance Smart Chain (Live)</Tooltip>}>
                  <img
                    src='assets/chain/BSC.f85eaacb.svg'
                    width={50}
                    className='mx-2'
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='m-auto'>
              <Nav.Link eventKey='ETHEREUM'>
                <OverlayTrigger
                  overlay={<Tooltip>ETHEREUM (Coming Soon)</Tooltip>}>
                  <img
                    src='assets/chain/GfxH7jO3SN2EEjXckvQE_6pMT4nm3uX2brq9P.png'
                    className='mx-2 img-fade'
                    width={50}
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='m-auto'>
              <Nav.Link eventKey='POLYGON'>
                <OverlayTrigger
                  overlay={<Tooltip>POLYGON (Coming Soon)</Tooltip>}>
                  <img
                    src='assets/chain/POLYGON.fd162129.svg'
                    width={50}
                    className='mx-2 img-fade'
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='m-auto'>
              <Nav.Link eventKey='AVALANCHE'>
                <OverlayTrigger
                  overlay={<Tooltip>AVALANCHE (Coming Soon)</Tooltip>}>
                  <img
                    src='assets/chain/AVALANCHE.05c253e5.svg'
                    width={50}
                    className='mx-2 img-fade'
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className='m-auto'>
              <Nav.Link eventKey='MOONBEAM'>
                <OverlayTrigger
                  overlay={<Tooltip>MOONBEAM (Coming Soon)</Tooltip>}>
                  <img
                    src='assets/chain/moon.png'
                    width={50}
                    className='mx-2 img-fade'
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>

            <Nav.Item className='m-auto'>
              <Nav.Link eventKey='FANTOM'>
                <OverlayTrigger
                  overlay={<Tooltip>FANTOM (Coming Soon)</Tooltip>}>
                  <img
                    src='assets/chain/FANTOM.ce1b0eef.svg'
                    width={50}
                    className='mx-2 img-fade'
                    alt=''
                  />
                </OverlayTrigger>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey='Binance'>
              <div className='row '>
                <div className='col-lg-4 m-auto'>
                  <PizzaXPool />
                </div>
                <div className='col-lg-4 m-auto'>
                  <Burger />
                </div>
                <div className='col-lg-4 m-auto'>
                  <BNBPool />
                </div>
                <div className='col-lg-4 m-auto'>
                  <BUSDPool />
                </div>
                <div className='col-lg-4 m-auto'>
                  <CAKEPool />
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey='ETHEREUM'>
              <div className='row '>
                <div className='col-lg-4 m-auto'>
                  <ETHPool />
                </div>
                <div className='col-lg-4 m-auto'>
                  <USDTPool />
                </div>
                <div className='col-lg-4 m-auto'>
                  <SHIBPool />
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey='POLYGON'>
              <POLYPool />
            </Tab.Pane>
            <Tab.Pane eventKey='AVALANCHE'>
              <AVALPool />
            </Tab.Pane>
            <Tab.Pane eventKey='MOONBEAM'>
              <div className='row '>
                <div className='col-lg-6 m-auto'>
                  <MoonPool />
                </div>
                <div className='col-lg-6 m-auto'>
                  <STELLAPool />
                </div>
              </div>
            </Tab.Pane>
            
            <Tab.Pane eventKey='FANTOM'>
              <FANPool />
            </Tab.Pane>
            
          </Tab.Content>
        </Tab.Container> */}
      </div>
      <ToastContainer position='bottom-center' theme='dark' />
    </>
  );
};

export default CardBox;
