/** @format */

import React from "react";
import { useAccount } from "wagmi";
const Referance = () => {
  const { address } = useAccount();

  return (
    <div className='container mt-3'>
      <div className='ref w-100 m-auto'>
        <div className='card-body'>
          <h4 className='text-center'>Referral Link</h4>

          {/* Input */}
          <div className='input-group inp2  mb-3 mt-4'>
            <input
              type='text'
              className='form-control'
              defaultValue={
                address ? `https://PizzaX.club/?ref=${address}` : ""
              }
              placeholder=''
              aria-describedby='basic-addon2'
            />
          </div>
          <div className='row text-center p-3'>
            <p>
              <small>
                17% of Dev fee auto transferred to referral address.
              </small>
            </p>
          </div>
        </div>
      </div>
      G
    </div>
  );
};

export default Referance;
