import ERC20ABI from '../assets/abi-erc20.json'
import BAKEBEAN from "../assets/bekedBeans.json"
import TOKEN from '../assets/token.json'


export function getERC20Contract(tokenAddress, web3) {

  return web3
    ? new web3.eth.Contract(ERC20ABI, tokenAddress, {
        from: web3.eth.defaultAccount,
      })
    : null
}



export function getTokenContract(tokenAddress, web3) {

  return web3
    ? new web3.eth.Contract(TOKEN, tokenAddress, {
        from: web3.eth.defaultAccount,
      })
    : null
}


export function getBakeContract(tokenAddress, web3) {

  return web3
    ? new web3.eth.Contract(BAKEBEAN, tokenAddress, {
        from: web3.eth.defaultAccount,
      })
    : null
}

